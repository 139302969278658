import { Margins } from 'components/shared/types';
import { getMarginFromProps } from 'components/shared/utils';
import { FC, createElement } from 'react';

type ContainerProps = {
  as?: keyof JSX.IntrinsicElements;
  style?: React.CSSProperties;
  children?: React.ReactNode;
} & Margins &
  React.HTMLAttributes<HTMLElement>;

const Container: FC<ContainerProps> = ({
  as = 'div',
  style,
  children,
  ...rest
}) => {
  const marginStyle = getMarginFromProps(rest);

  return createElement(
    as,
    { style: { ...marginStyle, ...style }, ...rest },
    children
  );
};

export default Container;
