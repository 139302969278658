import { FC, useCallback, ButtonHTMLAttributes } from 'react';

import Spinner from 'components/Spinner/Spinner';

export const LINK_BUTTON_TYPE = "LINK_BUTTON_TYPE";
export const FILLED_BUTTON_TYPE = "FILLED_BUTTON_TYPE";
export const FILLED_BUTTON_TYPE_DARK = "FILLED_BUTTON_TYPE_DARK";
export const OUTLINED_BUTTON_TYPE_DARK = "OUTLINED_BUTTON_TYPE_DARK";

interface IButton {
  title: string | JSX.Element,
  onClick: () => void,
  isLoading?: boolean,
  buttonType?: typeof LINK_BUTTON_TYPE | typeof FILLED_BUTTON_TYPE | typeof FILLED_BUTTON_TYPE_DARK | typeof OUTLINED_BUTTON_TYPE_DARK,
  className?: string,
  htmlAttributes?: ButtonHTMLAttributes<HTMLButtonElement>,
}

const Button: FC<IButton> = ({
  title,
  onClick,
  buttonType = FILLED_BUTTON_TYPE,
  isLoading = false,
  className,
  htmlAttributes,
}) => {
  const handleOnClick = useCallback(() => {
    if (isLoading) {
      return;
    }
    onClick();
  }, [isLoading, onClick]);

const getClass = useCallback (() => {
  let classNameArray: string[] = [];
  let inititalClassName = "button-body-filled";

  switch (buttonType) {
    case LINK_BUTTON_TYPE: 
    inititalClassName = "button-body-link";
      break;
    case FILLED_BUTTON_TYPE_DARK:
      inititalClassName = "button-body-filled-dark";
      break;
    case OUTLINED_BUTTON_TYPE_DARK:
      inititalClassName = "button-body-outlined-dark";
    default:
      break;
  }
  classNameArray.push(inititalClassName);
  if (className) {
    classNameArray.push(className);
  }
  return classNameArray.join(" ");
}, [buttonType, className]);

  return (
    <button
      type={htmlAttributes?.type}
      onClick={handleOnClick}
      className={getClass()}
      area-label={title}
      style={htmlAttributes?.style}
    >
      {!isLoading && title}
      {isLoading && <Spinner />}
    </button>
  );
}

export default Button;
